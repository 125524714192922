import { Component, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { formatDate, sanitizeString } from '@/support/String';
import { parseWarning } from '@/support/Warning';
import { Report as ReportModel, Answer, ReportIncludes } from '@/models/Report';
import { Damage } from '@/models/DamageNew';
import { DamageVisibility } from '@/components/damage-panel/DamagePanel';
import { AlertBar } from '@/support/AlertBar';
import { debounce } from 'lodash';
import Storage from '@/support/Storage';
import { Lock } from '@/support/Lock';
import IdleWarningDialog from '@/components/IdleWarningDialog';
import { Options } from '@/components/mi-dialog/MiDialog';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';
import { DateTime } from 'luxon';
import { ReportStatus } from '@/support/ReportStatus';

@Component<Manager>({
  filters: {
    dateFormat: (date: string) => {
      if (! date) { return ''; }
      return formatDate(date, 'dd-LL-yyyy HH:mm');
    },
  },
})
export default class Manager extends IdleWarningDialog {
  public $pageTitle = 'Technisch Coordinator';

  // LOCK
  protected lock: Lock = new Lock();

  protected lockKey = '';

  protected ReportStatus = ReportStatus;

  protected reportModel: ReportModel = new ReportModel();

  protected reportClone: ReportModel | null = null;

  protected damages: Damage[] = [];

  protected fileUrl = '';

  protected currentPdfType = '';

  protected isLoading = false;

  protected isQuickPdf = false;

  protected isPreviewPdf = false;

  // approval bar
  protected approvalDialogOpen = false;

  protected approval = '';

  protected status = '';

  protected title = '';

  protected isZienswijze = false;

  protected isStuwmeer = false;

  protected isObjection = false;

  protected isVeldwerkRejected = false;

  protected isObjectionRejected = false;

  protected isStuwmeerRejected = false;

  protected isZienswijzeRejected = false;

  protected debounceExplanation:Function = debounce(this.updateExplanation, 1500);

  protected hasDamageAnomaly?: boolean = false;

  protected damageVisibility: DamageVisibility = {
    validations: true,
    manager: true,
    edit: true,
    rejectReasons: true,
    internal: true,
    reject: true,
    approve: true,
  };

  protected generalKeys: GeneralKey = GeneralKeys;

  protected isUploadingFile = false;

  protected activeTab = 'tab-schades';

  protected userFilter: UserFilter = {};

  protected vatTypes: NumberArray[] = [
    {
      name: 6,
      value: 6,
    },
    {
      name: 9,
      value: 9,
    },
  ];

  protected isAllowedToView = false;

  protected isAllowedToViewDialog = false;

  protected created() {
    window.onbeforeunload = () => {
      this.lock.unlock(this.lockKey);
    };
  }

  public mounted() {
    // LOCK
    this.createLock();

    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    await this.getReport();
    await this.getDamages();
    this.getStorageValues();

    this.isLoading = false;
  }

  protected async createLock() {
    this.lockKey = `report:${this.$route.params.id}:editor:manager`;
    await this.lock.checkLock(this.lockKey);
    await this.lock.initLock(this.lockKey);
    this.setAlert();
  }

  protected get isLockedByUser(): boolean {
    return this.lock.isLockedByUser;
  }

  protected setAlert() {
    if (this.lock.user) {
      this.$store.state.alert.message = AlertBar.setIsLockedMessage(this.lock.user);
      this.$store.state.alert.show = true;
    } else {
      this.$store.state.alert.show = false;
    }
  }

  protected getStorageValues() {
    if (Storage.get('manager_currentPdf')) {
      this.setCurrentPdf(`${Storage.get('manager_currentPdf')}`);
    }
  }

  protected destroyed() {
    this.lock.unlock(this.lockKey);
    Storage.delete('manager_currentPdf');
    Storage.delete('manager_panels');
  }

  protected async getReport() {
    await new ReportModel()
      .include([
        ReportIncludes.ANSWERS,
        ReportIncludes.VALIDATIONS,
        ReportIncludes.REJECT_REASONS,
        ReportIncludes.TOTAL_REPAIRS,
        ReportIncludes.HAS_DAMAGE_ANOMALY,
        ReportIncludes.OBJECTION,
      ])
      .find(this.$route.params.id)
      .then((reportModel: ReportModel) => {
        this.reportModel = reportModel;
        if (! this.fileUrl) {
          this.fileUrl = reportModel.links.quick;
          this.currentPdfType = 'quick';
        }

        this.initBreadcrumb();
        this.setGeneralInfo();
        this.pingReport();
        this.showApprovalButtons();

        this.isAllowedToViewDialog = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getDamages() {
    this.damages = await new Damage()
      .refactor()
      .include('validations')
      .filter({
        report: this.$route.params.id,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async claimDossier() {
    await this.reportModel
      .update({
        technical_coordinator: this.$store.state.Auth.uuid,
      });

    this.$store.dispatch('openDialog', this.dialogOptionsConfirmClaim);
  }

  protected showApprovalButtons() {
    this.isZienswijze = this.reportModel.isZienswijzeReport;
    if (this.reportModel.type) {
      this.isStuwmeer = this.reportModel.isStuwmeerRegelingReport;
      this.isObjection = this.reportModel.isBezwaarReport;
    }

    const isSubmitted = !! (this.reportModel.submit_count && this.reportModel.submit_count > 0);

    this.isVeldwerkRejected = this.reportModel.status === 'veldwerk_rejected' || isSubmitted;
    this.isObjectionRejected = this.reportModel.status === 'objection_veldwerk_rejected' || isSubmitted;
    this.isZienswijzeRejected = this.reportModel.status === 'zienswijze_veldwerk_rejected' || isSubmitted;
    this.isStuwmeerRejected = this.reportModel.status === 'smr_veldwerk_rejected' || isSubmitted;

    this.hasDamageAnomaly = this.reportModel.has_damage_anomaly;
  }

  protected toggleApproveDialog(status: string, title: string, approval: string) {
    this.approval = approval;
    this.status = status;
    this.title = title;
    this.approvalDialogOpen = true;
  }

  protected allowedToView(allowed: boolean) {
    this.isAllowedToView = allowed;
  }

  protected setCurrentPdf(type: string) {
    this.currentPdfType = type;
    Storage.set('manager_currentPdf', type);

    if (type === 'quick') {
      this.fileUrl = this.reportModel.links.quick;
    } else if (type === 'preview') {
      this.fileUrl = this.reportModel.links.preview;
    } else {
      this.fileUrl = '';
    }
  }

  protected pingReport() {
    if (! this.reportModel || ! this.reportModel.uuid) {
      return;
    }

    this.reportModel
      .pingReport(this.reportModel.uuid)
      .then((report: ReportModel) => {
      //
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle rapporten', path: '/reports' },
          { name: this.reportModel.case_number, path: `/reports/${this.reportModel.uuid}` },
          { name: 'Technisch Coordinator' },
        ],
      });
  }

  protected parseWarning(alert: string) {
    return parseWarning(alert);
  }

  protected formatDateSeconds(date: string) {
    return formatDate(date, 'dd-LL-yyyy HH:mm');
  }

  protected getVatText() {
    if (this.reportModel && this.reportModel.calculation_model_vat_low) {
      return `met ${this.reportModel.calculation_model_vat_low}% BTW`;
    }
    return '';
  }

  protected parseIsLate() {
    if (this.reportModel.is_late) {
      return 'Ja';
    }
    return 'Nee';
  }

  protected setGeneralInfo() {
    const keys = Object.keys(this.generalKeys);
    if (! this.reportModel.answers) {
      return;
    }

    this.reportModel.answers.forEach((anwser: Answer) => {
      if (keys.includes(anwser.key ? anwser.key : '')) {
        (this.generalKeys as any)[anwser.key ? anwser.key : ''].value = anwser.value;
      }
    });
  }

  protected navigateToUrl(url: string) {
    return this.$router.push(url);
  }

  protected sanitizeString(value: string): string {
    return sanitizeString(value, true);
  }

  protected updateExplanation() {
    const payload = {
      internal_reject_comment: this.reportModel.internal_reject_comment,
    };

    this.reportModel
      .update(payload)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected handlePanelChanged(panels: boolean[]) {
    Storage.set('manager_panels', JSON.stringify(panels));
  }

  protected get panelsFromStorage() {
    return JSON.parse(`${Storage.get('manager_panels')}`);
  }

  protected get hoorzittingHasPassed(): boolean {
    const plannedAt = this.reportModel.planned_at ? DateTime.fromSQL(this.reportModel.planned_at) : '';
    return DateTime.local() > plannedAt;
  }

  protected get dialogOptionsConfirmClaim(): Options {
    return {
      title: 'Gelukt!',
      text: 'U bent nu de technisch coördinator van dit dossier.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.initialize();
  }
}

export interface StringArray {
  [key: string]: string;
}

export interface NumberArray {
  [key: string]: number;
}

interface UserFilter {
  search?: string;
}

interface Mask {
  [key: string]: string;
}
